<template>
  <div class="user-setting-table">
    <el-table
      height="82vh"
      :data="userList"
      class="clickable-rows"
      :header-cell-style="{
        fontWeight: '700',
        color: '#383838',
      }"
      :cell-style="{ color: '#636363', 'font-weight': '700' }"
      style="margin-top: 28px"
    >
      >
      <el-table-column :label="$t('user.userTable.name')" min-width="30%">
        <template slot-scope="scope">
          <div class="avatar-with-name-center">
            <personal-avatar
              :size="35"
              :avatar="scope.row.avatar"
              style="margin-right: 10px"
              :nickname="scope.row.name"
              :tool-tip-disabled="true"
              :colorid="scope.row.accountId"
            ></personal-avatar>
            <span>{{ scope.row.name }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="role"
        :label="$t('user.userTable.role')"
        min-width="30%"
        :placeholder="$t('user.userTable.placeholder')"
      >
        <template slot-scope="scope">
          <el-select
            :ref="'roleSelect' + scope.row.accountId"
            v-model="scope.row.roleId"
            filterable
            @change="
              handleSelectChange(
                $event,
                scope,
                'roleSelect' + scope.row.accountId
              )
            "
            @focus="setSelectWidth($event, `roleSelect${scope.row.accountId}`)"
          >
            <el-option
              v-for="item in rolesList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column
        prop="project"
        :label="$t('user.userTable.position')"
        min-width="30%"
      >
        <template slot-scope="scope">
          <el-select
            :ref="'jobSelect' + scope.row.accountId"
            v-model="scope.row.jobId"
            filterable
            :placeholder="$t('user.userTable.placeholder2')"
            @change="
              (val) => {
                handleJobSelectChange(
                  val,
                  scope,
                  'jobSelect' + scope.row.accountId
                );
              }
            "
            @focus="setSelectWidth($event, `jobSelect${scope.row.accountId}`)"
          >
            <el-option
              v-for="item in jobsList"
              :key="item.jobId"
              :label="item.name"
              :value="item.jobId"
            ></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column :label="$t('btn.optionBtn')" min-width="10%">
        <template slot-scope="scope">
          <el-dropdown >
            <span class="el-dropdown-link" style="color: #84aace">
              &nbsp;&nbsp;
              <i
                class="iconfont icon-a-216gengduo-shuxiang"
                style="color: rgb(48, 100, 143)"
              />
              &nbsp;&nbsp;
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                icon="el-icon-delete"
                @click.native="handleDeleteMember(scope.$index, scope.row)"
              >
                {{ $t("user.userTable.options.remove") }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <!-- <span id="hide"></span> -->
  </div>
</template>

<script>
import {
  get_project_members,
  update_project_member,
  delete_project_member,
} from "@/network/projectMember/index.js";
import { get_jobAuthorities } from "@/network/home/index.js";
import "@/components/svgIcon";
import PersonalAvatar from "../../../components/personal";
import { mapActions } from "vuex";

export default {
  components: { PersonalAvatar },
  props: {
    project_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userList: [],
      rolesList: [
        { value: "MANAGER", label: this.$t("user.manager") },
        { value: "MEMBER", label: this.$t("user.ordinary") },
      ],
      jobsList: [],
      account_id: this.$store.getters.user_info.accountId,
      selectWidth: "",
    };
  },
  computed: {
    users_in_project() {
      return this.userList.map((item) => {
        return item.name;
      });
    },
  },
  watch: {
    project_id: {
      handler() {
        this.getMemberData();
        this.getJobsList();
      },
      immediate: true,
    },
    userList: {
      handler() {
        this.$emit("user_in_project", this.userList);
      },
    },
  },
  created() {
    this.getJobsList();
  },
  methods: {
    ...mapActions(["setProjectUserList"]),
    getMemberData() {
      if (this.project_id === -1) {
        return;
      } else {
        this.userList = [];
        get_project_members(this.project_id)
          .then((res) => {
            res.map((item) => {
              if (item.deleted !== 1) {
                this.userList.push({
                  name: item.nickname,
                  avatar: item.avatar,
                  email: item.email,
                  roleId: item.projectRole,
                  jobName: item.jobName,
                  jobId: item.jobId,
                  accountId: item.accountId,
                });
              }
            });
          })
          .then((res) => {
            this.userList.map((item) => {
              this.setSelectWidth("", `roleSelect${item.accountId}`);
              this.setSelectWidth("", `jobSelect${item.accountId}`);
            });
          })
          .catch((err) => {});
      }
    },
    getJobsList() {
      if (this.project_id === -1) {
        return;
      } else {
        get_jobAuthorities(this.project_id).then((res) => {
          this.jobsList = res;
        });
      }
    },
    handleDeleteMember(index, row) {
      delete_project_member(this.project_id, row.accountId).then((res) => {
        this.userList.splice(index, 1);
        this.setProjectUserList(this.project_id);
      });
    },
    handleSelectChange(value, user, ref) {
      update_project_member(
        user.row.accountId,
        this.project_id,
        user.row.jobId,
        user.row.roleId
      )
        .then((res) => {
          this.$message.success(this.$t("canvas.messages.editSuccess"));
        })
        .catch((err) => {
          // 更新失败之后的操作
        });
      this.$nextTick(() => {
        this.setSelectWidth("", ref);
      });
    },
    handleJobSelectChange(jobId, user, ref) {
      update_project_member(
        user.row.accountId,
        this.project_id,
        jobId,
        user.row.roleId
      )
        .then((res) => {
          this.$message.success(this.$t("canvas.messages.editSuccess"));
        })
        .catch((err) => {
          // 更新失败之后的操作
        });
      this.$nextTick(() => {
        this.setSelectWidth("", ref);
      });
    },
    setSelectWidth(value, ref) {
      // let hide = document.getElementById("hide");
      // if (this.$refs[ref].selectedLabel === "") {
      //   hide.textContent = this.$refs[ref].placeholder;
      // } else {
      //   hide.textContent = this.$refs[ref].selectedLabel;
      // }
      // this.$refs[ref].$el.style.width = hide.offsetWidth + 45 + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
.user-setting-table {
  ::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 1px;
    height: 70vh;
  }
  .el-table {
    ::v-deep .el-input {
      font-size: 16px;
    }
  }
}
.with-box-shadow {
  box-shadow: 0px 2px 4px rgba(128, 128, 128, 0.61);
  margin: 4px;
}

.avatar-with-name-center {
  display: flex;
  align-items: center;
  .el-avatar {
    margin-right: 10px;
    flex-shrink: 0;
  }
}

#hide {
  position: absolute;
  white-space: pre;
  font: inherit;
  font-size: 16px;
  margin: 0;
  padding: 0;
  height: 0;
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.clickable-rows {
  cursor: default;

  height: 75vh;
  overflow: hidden;
  background: white;
}
</style>
