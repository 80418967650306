var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-setting-table"},[_c('el-table',{staticClass:"clickable-rows",staticStyle:{"margin-top":"28px"},attrs:{"height":"82vh","data":_vm.userList,"header-cell-style":{
      fontWeight: '700',
      color: '#383838',
    },"cell-style":{ color: '#636363', 'font-weight': '700' }}},[_vm._v(" > "),_c('el-table-column',{attrs:{"label":_vm.$t('user.userTable.name'),"min-width":"30%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"avatar-with-name-center"},[_c('personal-avatar',{staticStyle:{"margin-right":"10px"},attrs:{"size":35,"avatar":scope.row.avatar,"nickname":scope.row.name,"tool-tip-disabled":true,"colorid":scope.row.accountId}}),_c('span',[_vm._v(_vm._s(scope.row.name))])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"role","label":_vm.$t('user.userTable.role'),"min-width":"30%","placeholder":_vm.$t('user.userTable.placeholder')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{ref:'roleSelect' + scope.row.accountId,attrs:{"filterable":""},on:{"change":function($event){return _vm.handleSelectChange(
              $event,
              scope,
              'roleSelect' + scope.row.accountId
            )},"focus":function($event){return _vm.setSelectWidth($event, `roleSelect${scope.row.accountId}`)}},model:{value:(scope.row.roleId),callback:function ($$v) {_vm.$set(scope.row, "roleId", $$v)},expression:"scope.row.roleId"}},_vm._l((_vm.rolesList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"project","label":_vm.$t('user.userTable.position'),"min-width":"30%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{ref:'jobSelect' + scope.row.accountId,attrs:{"filterable":"","placeholder":_vm.$t('user.userTable.placeholder2')},on:{"change":(val) => {
              _vm.handleJobSelectChange(
                val,
                scope,
                'jobSelect' + scope.row.accountId
              );
            },"focus":function($event){return _vm.setSelectWidth($event, `jobSelect${scope.row.accountId}`)}},model:{value:(scope.row.jobId),callback:function ($$v) {_vm.$set(scope.row, "jobId", $$v)},expression:"scope.row.jobId"}},_vm._l((_vm.jobsList),function(item){return _c('el-option',{key:item.jobId,attrs:{"label":item.name,"value":item.jobId}})}),1)]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('btn.optionBtn'),"min-width":"10%"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-dropdown',[_c('span',{staticClass:"el-dropdown-link",staticStyle:{"color":"#84aace"}},[_vm._v("    "),_c('i',{staticClass:"iconfont icon-a-216gengduo-shuxiang",staticStyle:{"color":"rgb(48, 100, 143)"}}),_vm._v("    ")]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"},nativeOn:{"click":function($event){return _vm.handleDeleteMember(scope.$index, scope.row)}}},[_vm._v(" "+_vm._s(_vm.$t("user.userTable.options.remove"))+" ")])],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }